import React from 'react';
import { Link } from "gatsby";

import Logo1 from "../../images/logo_1.inline.svg"
import Logo2 from "../../images/logo_2.inline.svg"
import Logo3 from "../../images/logo_3.inline.svg"
import Hamburger from "../../images/hamburger.inline.svg"

import classes from "./Toolbar.module.css"
import "../../scss/theme/navbar.scss"

const Toolbar = (props) => {
  
  const hideDropdownOnLeave = e => {
    // fire click-on-body-event on Dropdown leave
    const c = document.getElementsByClassName("uk-container")[0]
    c.click()
  }

  return (
    <div className="uk-container">
        <div uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky">
          <nav className="uk-navbar-container uk-navbar" uk-navbar="">
            <div className="uk-navbar-left fib-navbar-left">
              <Link
                className="fib-navbar-logo"
                to="/"
                onMouseEnter={props.setNewLogo}
              >
                <Logo1 className={"fib-navbar-logo-" + props.logover + "-top"} />
                <Logo2 className={"fib-navbar-logo-" + props.logover + "-middle"} />
                <Logo3 className={"fib-navbar-logo-" + props.logover + "-bottom"} />
              </Link>
            </div>
            <div className="uk-navbar-right fib-navbar-right">
              <ul className="uk-navbar-nav uk-visible@s">
                <li className="uk-parent">
                  <Link
                    to="/leistung-brandschutz"
                    className="fib-navbar-item"
                    activeClassName="fib-navbar-item-active"
                  >
                    <span className="fib-navbar-link-hover">Leistungen</span>
                  </Link>
                  <div
                    role="link"
                    tabIndex="0"
                    className="uk-navbar-dropdown uk-navbar-dropdown-bottom-left"
                    onMouseLeave={hideDropdownOnLeave}
                  >
                    <ul className="uk-nav uk-navbar-dropdown-nav">
                      <li>
                        <Link
                          to="/leistung-brandschutz"
                          className="fib-navbar-item"
                          activeClassName="fib-navbar-item-active"
                        >
                          Brandschutz
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/leistung-bauphysik"
                          className="fib-navbar-item"
                          activeClassName="fib-navbar-item-active"
                        >
                          Bauphysik
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/leistung-baugutachten"
                          className="fib-navbar-item"
                          activeClassName="fib-navbar-item-active"
                        >
                          Baugutachten
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <Link
                    to="/team"
                    className="fib-navbar-item"
                    activeClassName="fib-navbar-item-active"
                  >
                    <span className="fib-navbar-link-hover">Über uns</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/kontakt"
                    className="fib-navbar-item"
                    activeClassName="fib-navbar-item-active"
                  >
                    <span className="fib-navbar-link-hover">Kontakt</span>
                  </Link>
                </li>
              </ul>
              
              {/* Hamburger Icon visible only on small screens */}
              <div className="uk-hidden@s" role="button" tabIndex="0" onClick={props.toggleClicked}>
                <Hamburger />
              </div> 
            </div>
          </nav>
        </div>
      </div>
  );
};

export default Toolbar;