import React from 'react';
import { Link } from "gatsby";

import Logo1White from "../../images/logo_1_white.inline.svg"
import Logo2White from "../../images/logo_2_white.inline.svg"
import Logo3White from "../../images/logo_3_white.inline.svg"
import Close from "../../images/close.inline.svg"

import classes from "./OffCanvas.module.css"
import "../../scss/theme/navbar.scss"

const OffCanvas = (props) => {
  let attachedClasses = [classes.OffCanvas];
  attachedClasses.push (props.show ? classes.Show : classes.Hide);

  return (
    <div className={attachedClasses.join(' ')}>
      <div className="uk-flex uk-flex-between uk-flex-middle">
        <Link
          className="fib-navbar-logo"
          to="/"
          onMouseEnter={props.setNewLogo}
        >
          <Logo1White className={"fib-navbar-logo-" + props.logover + "-top"} />
          <Logo2White
            className={"fib-navbar-logo-" + props.logover + "-middle"}
          />
          <Logo3White
            className={"fib-navbar-logo-" + props.logover + "-bottom"}
          />
        </Link>
        <div role="button" tabIndex="0" onClick={props.toggleClicked}>
          <Close />
        </div>
      </div>
      <ul className="uk-nav">
        <hr />
        <li >
        <Link 
            onClick={props.toggleClicked}
            to="/leistung-brandschutz" 
            activeClassName="fib-navbar-item-active"                
            >
          Brandschutz
          </Link>
        </li>
        <li>
          <Link 
            onClick={props.toggleClicked}
            to="/leistung-bauphysik" >
            Bauphysik</Link>
        </li>
        <li>
          <Link
            onClick={props.toggleClicked}
            to="/leistung-baugutachten" >
            Baugutachten
          </Link>
        </li>
        <li>
          <Link 
            onClick={props.toggleClicked}
            to="/team" >
            Über uns
          </Link>
        </li>
        <li >
          <Link 
            onClick={props.toggleClicked}
            to="/kontakt" >
            Kontakt
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default OffCanvas;