import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import OffCanvas from "./OffCanvas/OffCanvas";
import Toolbar from "./Toolbar.js/Toolbar"

import "../scss/theme/navbar.scss"


const Navbar = ({ siteTitle }) => {
  const [logover, setLogover] = useState(Math.floor(Math.random() * 7) + 1);
  const [showOffCanvas, setOffCanvasVisible] = useState(false);

  const setNewLogo = () => {
    let x;
    do {
      x = Math.floor(Math.random() * 7) + 1
    } while (x === logover)
    setLogover(x);
  }

  const toggleOffCanvas = () => {
    // Update State depending on prev state
    // https://blog.logrocket.com/a-guide-to-usestate-in-react-ecb9952e406c/
    setOffCanvasVisible(prev => !prev);
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setNewLogo();
    }, 8000)
    return () => clearInterval(timer)
    // eslint-disable-next-line
  }, [])

  return (
    <header>
      <OffCanvas show={showOffCanvas} logover={logover} setNewLogo={setNewLogo } toggleClicked={toggleOffCanvas}/>
      <Toolbar logover={logover} setNewLogo={setNewLogo} toggleClicked={toggleOffCanvas}/>

      {/* <h1 className="navbar-brand">
          <Link to="/"> {siteTitle} </Link>
        </h1> */}
    </header>
  )
}

Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar
