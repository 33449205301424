/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Obfuscate from "react-obfuscate"
//  import CookieConsent from "react-cookie-consent" // not in use

import Navbar from "./navbar"

import "../scss/layout.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    try {
      const UIkit = require("uikit/dist/js/uikit")
      const Icons = require("uikit/dist/js/uikit-icons")
      UIkit.use(Icons)
    } catch (e) {
      console.error(e)
    }
  }, [])

  return (
    <>
      <Navbar siteTitle={data.site.siteMetadata.title} />
      <div className="uk-container">
        <main>{children}</main>
      </div>

      <div className="fib-sp200"></div>
      <footer className="fib-footer-text uk-background-secondary">
        <div className="uk-container">
          <div className="fib-container-text">
            <div className="fib-sp40"></div>
            <div className="fib-footer-heading-big">freie ingenieure bau</div>
            <div>
              Dr. Belaschk + Krätschell
              <br />
              PartGmbB
            </div>
            <div className="fib-sp40"></div>
            <div className="uk-grid uk-grid-medium" uk-grid="">
              <div className="uk-width-5-12@s">
                Elvirasteig 11<br />
                14163 Berlin <br />
                <span itemProp="telephone">
                  <a className="fib-text-link" href="tel:+493098422780">
                    T +49 30 98422780
                  </a>
                </span>
                <br />
                <span itemProp="telephone">
                  <a className="fib-text-link" href="tel:+493022185925">
                    F +49 30 22185925
                  </a>
                </span>
                <br />
                <Obfuscate
                  className="fib-text-link"
                  email="info@freie-ingenieure-bau.de"
                >
                  info@freie-ingenieure-bau.de
                </Obfuscate>
              </div>
              <div className="uk-width-3-12@s">
                <div className="fib-footer-heading-small">Leistungen</div>
                <ul>
                  <li>
                    {
                      <Link
                        className="fib-text-link"
                        to="/leistung-brandschutz"
                      >
                        Brandschutz
                      </Link>
                    }
                  </li>
                  <li>
                    {
                      <Link className="fib-text-link" to="/leistung-bauphysik">
                        Bauphysik
                      </Link>
                    }
                  </li>
                  <li>
                    {
                      <Link
                        className="fib-text-link"
                        to="/leistung-baugutachten"
                      >
                        Baugutachten
                      </Link>
                    }
                  </li>
                </ul>
              </div>
              <div className="uk-width-3-12@s">
                <div className="fib-footer-heading-small">Büro</div>
                <ul>
                  <li>
                    {
                      <Link className="fib-text-link" to="/team">
                        Über uns
                      </Link>
                    }
                  </li>
                  <li>
                    {
                      <Link className="fib-text-link" to="/kontakt">
                        Kontakt
                      </Link>
                    }
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ paddingTop: `60px` }}></div>

            <p className="fib-footer-text-s">
              Sitz der Gesell&shy;schaft:&nbsp;Berlin
              <span style={{ display: `inline-block`, width: `6px` }} />|
              <span style={{ display: `inline-block`, width: `6px` }} />
              Regis&shy;ter&shy;gericht:&nbsp;Amts&shy;gericht
              Char&shy;lotten&shy;burg
              <span style={{ display: `inline-block`, width: `6px` }} />|
              <span style={{ display: `inline-block`, width: `6px` }} />
              Regis&shy;ter&shy;nummer:&nbsp;PR&nbsp;1438
            </p>
            <hr />
            <div className="uk-flex uk-flex-between fib-footer-text-xs">
              <ul className="uk-flex fib-footer-bottom-menu">
                <li>
                  {
                    <Link className="fib-text-link" to="/impressum">
                      Impressum
                    </Link>
                  }
                </li>
                <li>
                  {
                    <Link className="fib-text-link" to="/datenschutz">
                      Datenschutz
                    </Link>
                  }
                </li>
              </ul>
              <div>© {new Date().getFullYear()}</div>
            </div>

            {/* not in use
                <CookieConsent
                enableDeclineButton={true}
                flipButtons={true}
                disableStyles={true}
                // debug={true}
                containerClasses="fib-container-text"
                contentClasses="fib-cookieConsent-text"
                buttonClasses="fib-button-cookie-accept"
                declineButtonClasses="fib-button-cookie-decline"
                buttonText="Einverstanden"
                declineButtonText="Ablehnen"
                cookieName="fib_gdpr_google_analytics"
              >
                Zur Optimierung unserer Webseite nutzen wir Analyse-Cookies mit
                einer Anonymisierungsfunktion. Nährere Informationen finden Sie in
                unserer{" "}
                <Link className="fib-text-link" to="/datenschutz">
                  <span style={{ textDecoration: "underline" }}>
                    Datenschutzerklärung
                  </span>
                </Link>
                .
              </CookieConsent> */}

            <div className="fib-sp40"></div>
          </div>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
